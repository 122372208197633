<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">Dalin Store</h2>
        </b-link>

        <b-card-title class="mb-1"> Forget Your Password? 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your
          password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="forgetPassword" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="forgetPassword">
            <!-- email -->
            <b-form-group :label="$t('field.email')" label-for="login-email">
              <validation-provider
                vid="email"
                #default="{ errors }"
                :name="$t('field.email')"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="data.email"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="data.status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <n-button-loading
              type="submit"
              variant="primary"
              :loading="loading"
              :disabled="invalid"
              block
            >
              {{ $t('general.resetLink') }}
            </n-button-loading>
            <b-card-text class="text-center mt-2">
              <b-link :to="{ name: 'login' }">
                <feather-icon icon="ChevronLeftIcon" /> Back to login
              </b-link>
            </b-card-text>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { initialAbility } from '@/libs/acl/config'
import NPasswordInput from '@/components/NPasswordInput'
import NButtonLoading from '@/components/NButtonLoading'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    NPasswordInput,
    NButtonLoading,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {
        email: '',
        password: '',
        status: '',
      },
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    forgetPassword() {
      this.$refs.forgetPassword.validate().then((success) => {
        if (success) {
          this.loading = true
          useJwt
            .forgetPassword({
              ...this.data,
            })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Email Sent.`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `The reset email has been sent.`,
                },
              })
            })
            .catch((error) => {
              this.loading = false
              if (error.response?.status === 422) {
                this.$refs.forgetPassword.setErrors(error.response.data.message)
              }
            })
          setTimeout(() => (this.loading = false), 3000)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
